<template>
  <b-card class="border" no-body style="padding: 1rem;">
    <b-card-header class="p-0 mb-1">
      <span style="font-size: 1.2rem; font-weight: bold;">
        {{ data.title }}
      </span>
    </b-card-header>

    <div id="accumulate-daily-unique-leads" class="graph" />

    <div class="text-white">
      {{ hiddenMessage }}
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import * as echarts from 'echarts'

export default {
  components: {
    BCard,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hiddenMessage: 'Loading', // ข้อความที่ซ่อนเอาไว้ เพื่อให้เกิดการเรียก updated() หลังจากข้อมูลมีการเปลี่ยนแปลงใหม่
      xAxisData: [],
      totalLeadsData: [],
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newData) {
        this.xAxisData = newData.leadPerformance.accumulate.map(data => data.on)
        this.totalLeadsData = newData.leadPerformance.accumulate.map(data => data.totalForm + data.totalCall + data.totalChatbot)

        const graphElement = document.getElementById('accumulate-daily-unique-leads')

        // ตอนโหลดข้อมูลครั้งแรกมันจะหา DOM ไม่เจอ ต้องรอมันวาดหน้าจอก่อน
        if (graphElement !== null) {
          const graphObject = echarts.init(graphElement)
          graphObject.setOption(this.getGraphOption())
        }

        this.hiddenMessage = ''
      },
    },
  },
  beforeUpdate() {
    this.disposeGraph()
  },
  beforeDestroy() {
    this.disposeGraph()
  },
  methods: {
    getGraphOption() {
      return {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['Total Leads'],
        },
        grid: {
          left: '2%',
          right: '4%',
          bottom: '2%',
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xAxisData,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Total Leads',
            type: 'line',
            data: this.totalLeadsData,
          },
        ],
      }
    },
    disposeGraph() {
      if (this.chart) {
        this.chart.dispose()
      }
    },
  },
}
</script>

<style scoped>
.graph {
  width: 100%;
  height: 500px;
}
</style>
