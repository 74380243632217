<template>
  <b-card class="border" no-body style="padding: 1rem;">
    <b-card-header class="p-0 mb-1">
      <span style="font-size: 1.2rem; font-weight: bold;">
        {{ data.title }}
      </span>
    </b-card-header>

    <b-table
      class="m-0"
      show-empty
      striped
      sticky-header="100%"
      :small="true"
      :responsive="true"
      :items="items"
      :fields="tableConfig.fields"
      :per-page="0"
      :sort-by="tableConfig.sortBy"
      :sort-direction="tableConfig.sortDirection"
      :no-sort-reset="true"
      :no-local-sorting="true"
    >
      <template #cell(clicks)="channels">
        <span>{{ new Intl.NumberFormat().format(channels.item.clicks) }}</span>
      </template>

      <template #cell(leads)="channels">
        <span>{{ new Intl.NumberFormat().format(channels.item.leads) }}</span>
      </template>

      <template #cell(results)="channels">
        <span>{{ new Intl.NumberFormat().format(channels.item.results) }}</span>
      </template>
    </b-table>

    <div class="text-white">
      {{ hiddenMessage }}
    </div>
  </b-card>
</template>

<script>
import moment from 'moment'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hiddenMessage: 'Loading', // ข้อความที่ซ่อนเอาไว้ เพื่อให้เกิดการเรียก updated() หลังจากข้อมูลมีการเปลี่ยนแปลงใหม่

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Channel',
            key: 'name',
            stickyColumn: false,
          },
          {
            label: 'Clicks',
            key: 'clicks',
            sortable: false,
          },
          {
            label: 'Leads',
            key: 'leads',
            sortable: false,
          },
          {
            label: 'Results',
            key: 'results',
            sortable: false,
          },
        ],
      },
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newData) {
        this.items = this.getItems(newData)
        this.hiddenMessage = ''
      },
    },
  },
  mounted() {
    this.items = this.getItems(this.data)
  },
  methods: {
    getItems(data) {
      if (data.analyticPerformance.length === 0) {
        return []
      }

      return data.analyticPerformance.reduce((channels, item) => {
        const leadData = this.getLeadData(data.leadPerformance, item.channelId)
        // const channelType = item.productTypeName.replace(/\s/g, '')

        const channel = {
          name: item.productName,
          clicks: item.clicks || 0,
          leads: leadData.totalChatbot + leadData.totalDirect + leadData.totalForm + leadData.answered + leadData.missed,
          results: item.result || 0,
          remark: item.remark,
        }

        return [...channels, channel]
      }, [])
    },
    getLeadData(leads, channelId) {
      const result = leads.channels.find(lead => lead.channelId === channelId)

      return result ? {
        answered: result.totalCall.answered,
        missed: result.totalCall.missed,
        totalChatbot: result.totalChatbot,
        totalDirect: result.totalDirect,
        totalForm: result.totalForm,
      } : {
        answered: 0,
        missed: 0,
        totalChatbot: 0,
        totalDirect: 0,
        totalForm: 0,
      }
    },

  },
}
</script>
