<template>
  <b-card class="border" no-body style="padding: 1rem;">
    <b-card-header class="p-0 mb-1">
      <span style="font-size: 1.2rem; font-weight: bold;">
        {{ data.title }}
      </span>
    </b-card-header>

    <div id="leads-funnel" class="graph" />

    <div class="text-white">
      {{ hiddenMessage }}
    </div>
  </b-card>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hiddenMessage: 'Loading', // ข้อความที่ซ่อนเอาไว้ เพื่อให้เกิดการเรียก updated() หลังจากข้อมูลมีการเปลี่ยนแปลงใหม่
      leadData: [],
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newData) {
        this.leadData = newData.leadPerformance.insideData.map(data => ({
          name: `(${data.current}) - ${data.label}`,
          value: data.current,
        }))

        // คู่มือ https://echarts.apache.org/examples/en/editor.html?c=funnel

        const graphElement = document.getElementById('leads-funnel')

        // ตอนโหลดข้อมูลครั้งแรกมันจะหา DOM ไม่เจอ ต้องรอมันวาดหน้าจอก่อน
        if (graphElement !== null) {
          const graphObject = echarts.init(graphElement)
          graphObject.setOption(this.getGraphOption())
        }

        this.hiddenMessage = ''
      },
    },
  },
  beforeUpdate() {
    this.disposeGraph()
  },
  beforeDestroy() {
    this.disposeGraph()
  },
  methods: {
    getGraphOption() {
      return {
        title: {
          text: 'Funnel',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}%',
        },
        toolbox: {
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        legend: {
          data: ['Show', 'Click', 'Visit', 'Inquiry', 'Order'],
        },
        series: [
          {
            type: 'funnel',
            left: '0%',
            top: 10,
            bottom: 10,
            width: '80%',
            min: 0,
            max: 100,
            minSize: '0%',
            maxSize: '100%',
            sort: 'descending',
            gap: 2,
            label: {
              show: true,
              position: 'right',
            },
            labelLine: {
              length: 25,
              lineStyle: {
                width: 1,
                type: 'solid',
              },
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1,
            },
            emphasis: {
              label: {
                fontSize: 20,
              },
            },
            data: this.leadData,
          },
        ],
      }
    },
    disposeGraph() {
      if (this.root) {
        this.root.dispose()
      }
    },
  },
}
</script>

<style scoped>
.graph {
  width: 100%;
  height: 500px;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 152px; /* ให้ความสูงเสมอกับ Recent Leads */
}
</style>
