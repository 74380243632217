<template>
  <b-card class="border" no-body style="padding: 1rem;">
    <b-card-header class="p-0 mb-1">
      <span style="font-size: 1.2rem; font-weight: bold;">
        {{ data.title }}
      </span>
    </b-card-header>

    <b-tabs v-model="tabIndex" pills>
      <b-tab title="All">
        <b-table
          ref="allTable"
          class="m-0"
          show-empty
          striped
          sticky-header="100%"
          :small="true"
          :responsive="true"
          :items="allItems"
          :fields="allTableConfig.fields"
          :per-page="0"
          :sort-by="allTableConfig.sortBy"
          :sort-direction="allTableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
        >
          <template #cell(fullName)="leads">
            <span>{{ getFullName(leads.item) }}</span>
          </template>

          <template #cell(dateTime)="leads">
            <span>{{ leads.item.submittedDateTime }}</span>
          </template>
        </b-table>
      </b-tab>

      <b-tab title="Form">
        <b-table
          class="m-0"
          show-empty
          striped
          sticky-header="100%"
          :small="true"
          :responsive="true"
          :items="formsItems"
          :fields="allTableConfig.fields"
          :per-page="0"
          :sort-by="allTableConfig.sortBy"
          :sort-direction="allTableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
        >
          <template #cell(fullName)="leads">
            <span>{{ getFullName(leads.item) }}</span>
          </template>

          <template #cell(dateTime)="leads">
            <span>{{ leads.item.submittedDateTime }}</span>
          </template>
        </b-table>
      </b-tab>

      <b-tab title="Call">
        <b-table
          class="m-0"
          show-empty
          striped
          sticky-header="100%"
          :small="true"
          :responsive="true"
          :items="callItems"
          :fields="allTableConfig.fields"
          :per-page="0"
          :sort-by="allTableConfig.sortBy"
          :sort-direction="allTableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
        >
          <template #cell(fullName)="leads">
            <span>{{ getFullName(leads.item) }}</span>
          </template>

          <template #cell(dateTime)="leads">
            <span>{{ leads.item.submittedDateTime }}</span>
          </template>
        </b-table>
      </b-tab>

      <b-tab title="Chatbot">
        <b-table
          class="m-0"
          show-empty
          striped
          sticky-header="100%"
          :small="true"
          :responsive="true"
          :items="chatbotItems"
          :fields="allTableConfig.fields"
          :per-page="0"
          :sort-by="allTableConfig.sortBy"
          :sort-direction="allTableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
        >
          <template #cell(fullName)="leads">
            <span>{{ getFullName(leads.item) }}</span>
          </template>

          <template #cell(dateTime)="leads">
            <span>{{ leads.item.submittedDateTime }}</span>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>

    <div class="text-white">
      {{ hiddenMessage }}
    </div>
  </b-card>
</template>

<script>
import moment from 'moment-timezone'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hiddenMessage: 'Loading', // ข้อความที่ซ่อนเอาไว้ เพื่อให้เกิดการเรียก updated() หลังจากข้อมูลมีการเปลี่ยนแปลงใหม่
      tabIndex: 0,

      allItems: [],
      allTableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Call/Form',
            key: 'fullName',
            stickyColumn: false,
          },
          {
            label: 'Date/Time',
            key: 'dateTime',
            sortable: false,
          },
          {
            label: 'Channel',
            key: 'productTypeName',
            sortable: false,
          },
          {
            label: 'Status',
            key: 'statusName',
            sortable: false,
          },
        ],
      },

      formsItems: [],
      formsTableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Call/Form',
            key: 'fullName',
            stickyColumn: false,
          },
          {
            label: 'Date/Time',
            key: 'dateTime',
            sortable: false,
          },
          {
            label: 'Channel',
            key: 'productTypeName',
            sortable: false,
          },
          {
            label: 'Status',
            key: 'statusName',
            sortable: false,
          },
        ],
      },

      callItems: [],
      callTableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Calls',
            key: 'fullName',
            stickyColumn: false,
          },
          {
            label: 'Date/Time',
            key: 'dateTime',
            sortable: false,
          },
          {
            label: 'Channel',
            key: 'productTypeName',
            sortable: false,
          },
          {
            label: 'Status',
            key: 'statusName',
            sortable: false,
          },
        ],
      },

      chatbotItems: [],
      chatbotTableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Message',
            key: 'fullName',
            stickyColumn: false,
          },
          {
            label: 'Date/Time',
            key: 'dateTime',
            sortable: false,
          },
          {
            label: 'Channel',
            key: 'productTypeName',
            sortable: false,
          },
          {
            label: 'Status',
            key: 'statusName',
            sortable: false,
          },
        ],
      },
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newData) {
        this.allItems = newData.all
        this.formsItems = newData.form
        this.callItems = newData.call
        this.chatbotItems = newData.chatbot
        this.hiddenMessage = ''
      },
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    getFullName(item) {
      // Form, Chatbot Lead
      if (item.phone || item.chatbot) {
        if (item.firstName) {
          return `${item.phone} - ${item.firstName} ${item.lastName}`.trim()
        }

        return item.phone
      }

      // Call Lead
      if (item.callerPhoneNumber) {
        return item.callerPhoneNumber
      }
    },
  },
}
</script>
