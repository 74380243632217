<template>
  <b-tab
    lazy
    title="SEO Client Reports"
    :disabled="data.disableTab || data.isLoading"
    :title-item-class="{ 'd-none': data.disableTab }"
  >
    <template #title>
      <b-spinner
        v-if="data.isLoading"
        variant="primary"
        type="border"
        small
        style="margin-right: 1px"
      />
      SEO Client Reports
    </template>

    <b-row>
      <b-col cols="12" style="height: calc(100vh - 380px); overflow: hidden;">
        <iframe :src="data.reportingUrl" style="width: inherit; height: inherit; border:0" allowfullscreen />
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        reportingUrl: '',
        disableTab: false,
        isLoading: false,
      }),
    },
  },
}
</script>
