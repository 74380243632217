<template>
  <b-card class="border">
    <b-card-header class="p-0 vertical-top" style="height: 60px;">
      <span style="font-size: 1.2rem; font-weight: bold;">
        {{ data.title }}
      </span>
      <b-card-text>
        <feather-icon
          v-b-popover.hover.topright="data.popover"
          icon="HelpCircleIcon"
          size="21"
          class="cursor-pointer"
        />
      </b-card-text>
    </b-card-header>
    <b-row class="text-center">
      <b-col cols="12" class="vertical-center mb-0" style="height: 160px;">
        <h1 style="font-size: 5rem; font-weight: bold;">
          {{ data.number }}
        </h1>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-top {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
</style>
