<template>
  <b-tab title="Dashboard">

    <!-- Number Section -->
    <section>
      <b-card-actions
        ref="numberSection"
        no-actions
        no-body
      >
        <b-row>
          <b-col cols="12" sm="6" xl="3">
            <Number class="number-block" :data="dataTotalLinkClicks" />
          </b-col>
          <b-col cols="12" sm="6" xl="3">
            <Number class="number-block" :data="dataTotalUniqueCalled" />
          </b-col>
          <b-col cols="12" sm="6" xl="3">
            <Number class="number-block" :data="dataTotalMissedCalled" />
          </b-col>
          <b-col cols="12" sm="6" xl="3">
            <Number class="number-block" :data="dataTotalUniqueFormsSubmitted" />
          </b-col>
          <b-col cols="12" sm="6" xl="3">
            <Number class="number-block" :data="dataTotalUniqueMessages" />
          </b-col>
          <b-col cols="12" sm="6" xl="3">
            <Number class="number-block" :data="dataTotalUniqueLeads" />
          </b-col>
          <b-col cols="12" sm="6" xl="3">
            <Number class="number-block" :data="dataTotalLeadsOnProcess" />
          </b-col>
          <b-col cols="12" sm="6" xl="3">
            <Number class="number-block" :data="dataTotalLeadsConverted" />
          </b-col>
        </b-row>
      </b-card-actions>
    </section>

    <!-- Lead Section -->
    <section>
      <b-row>
        <b-col cols="12" xl="6">
          <b-card-actions
            ref="leadsFunnelSection"
            no-actions
            no-body
          >
            <LeadsFunnelEChart :data="dataLeadsFunnel" />
          </b-card-actions>
        </b-col>
        <b-col cols="12" xl="6">
          <b-card-actions
            ref="recentLeadsSection"
            no-actions
            no-body
          >
            <RecentLeads :data="dataRecentLeads" />
          </b-card-actions>
        </b-col>
      </b-row>
    </section>

    <!-- How People Discovered You Section -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-card-actions
            ref="howPeopleDiscoveredYouSection"
            no-actions
            no-body
          >
            <HowPeopleDiscoveredYou :data="dataHowPeopleDiscoveredYou" />
          </b-card-actions>
        </b-col>
      </b-row>
    </section>

    <!-- Daily Unique Leads Section -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-card-actions
            ref="dailyUniqueLeadsSection"
            no-actions
            no-body
          >
            <DailyUniqueLeads :data="dataDailyUniqueLeads" />
          </b-card-actions>
        </b-col>
      </b-row>
    </section>

    <!-- Accumulated Daily Unique Leads Section -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-card-actions
            ref="accumulatedDailyUniqueLeadsSection"
            no-actions
            no-body
          >
            <AccumulatedDailyUniqueLeads :data="dataAccumulatedDailyUniqueLeads" />
          </b-card-actions>
        </b-col>
      </b-row>
    </section>

    <div class="text-white">
      {{ hiddenMessage }}
    </div>

  </b-tab>
</template>

<script>
import Number from '@/views/herovision/components/Dashboard/Number.vue'
import LeadsFunnelEChart from '@/views/herovision/components/Dashboard/LeadsFunnelEChart.vue'
import RecentLeads from '@/views/herovision/components/Dashboard/RecentLeads.vue'
import HowPeopleDiscoveredYou from '@/views/herovision/components/Dashboard/HowPeopleDiscoveredYou.vue'
import DailyUniqueLeads from '@/views/herovision/components/Dashboard/DailyUniqueLeads.vue'
import AccumulatedDailyUniqueLeads from '@/views/herovision/components/Dashboard/AccumulatedDailyUniqueLeads.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCardActions,
    Number,
    LeadsFunnelEChart,
    RecentLeads,
    HowPeopleDiscoveredYou,
    DailyUniqueLeads,
    AccumulatedDailyUniqueLeads,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hiddenMessage: 'Loading', // ข้อความที่ซ่อนเอาไว้ เพื่อให้เกิดการเรียก updated() หลังจากข้อมูลมีการเปลี่ยนแปลงใหม่
      dataTotalLinkClicks: {
        title: 'Total Link Clicks',
        popover: 'The number of clicks coming from your ads.',
        number: 0,
      },
      dataTotalUniqueCalled: {
        title: 'Total Unique Called',
        popover: 'The number of prospects who have made a call.',
        number: 0,
      },
      dataTotalMissedCalled: {
        title: 'Total Missed Calls',
        popover: 'The number of prospects who have made a call but left unanswered.',
        number: 0,
      },
      dataTotalUniqueFormsSubmitted: {
        title: 'Total Unique Forms Submitted',
        popover: 'The number of prospects who have contacted via forms.',
        number: 0,
      },
      dataTotalUniqueMessages: {
        title: 'Total Unique Messages',
        popover: 'The number of prospects who have started conversations and chatbots.',
        number: 0,
      },
      dataTotalUniqueLeads: {
        title: 'Total Unique Leads (All Channels)',
        popover: 'The unique number of prospects who have contacted via all channels.',
        number: 0,
      },
      dataTotalLeadsOnProcess: {
        title: 'Total Leads On Process',
        popover: 'The number of prospects who have 1% - 99% ongoing status.',
        number: 0,
      },
      dataTotalLeadsConverted: {
        title: 'Total Leads Converted',
        popover: 'The number of prospects who resulted in being customers.',
        number: 0,
      },
      dataLeadsFunnel: {
        title: 'Leads Funnel',
        leadPerformance: {
          insideData: [],
          outsideData: [],
          leftLeads: 0,
        },
      },
      dataRecentLeads: {
        title: 'Recent Leads',
        all: [],
        form: [],
        call: [],
        chatbot: [],
      },
      dataHowPeopleDiscoveredYou: {
        title: 'How People Discovered You',
        analyticPerformance: [],
        leadPerformance: [],
      },
      dataDailyUniqueLeads: {
        title: 'Daily Unique Leads',
        leadPerformance: {
          normal: [],
          accumulate: [],
        },
      },
      dataAccumulatedDailyUniqueLeads: {
        title: 'Accumulated Daily Unique Leads',
        leadPerformance: {
          normal: [],
          accumulate: [],
        },
      },
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        this.updateData()
        this.hiddenMessage = ''
      },
    },
  },
  methods: {
    updateData() {
      if (Object.keys(this.$refs).length) {
        const {
          leadPerformanceWithChannelLoading,
          analyticPerformanceWithChannelLoading,
          totalLeadsOnProcessLoading,
          totalLeadsConvertedLoading,
          leadsFunnelLoading,
          recentLeadsLoading,
          leadPerformanceWithAccumulateLoading,
        } = this.data

        this.$refs.numberSection.showLoading = leadPerformanceWithChannelLoading || analyticPerformanceWithChannelLoading || totalLeadsOnProcessLoading || totalLeadsConvertedLoading
        this.$refs.leadsFunnelSection.showLoading = leadsFunnelLoading
        this.$refs.recentLeadsSection.showLoading = recentLeadsLoading
        this.$refs.howPeopleDiscoveredYouSection.showLoading = leadPerformanceWithChannelLoading || analyticPerformanceWithChannelLoading
        this.$refs.dailyUniqueLeadsSection.showLoading = leadPerformanceWithAccumulateLoading
        this.$refs.accumulatedDailyUniqueLeadsSection.showLoading = leadPerformanceWithAccumulateLoading
      }

      this.dataTotalLinkClicks.number = new Intl.NumberFormat().format(this.getTotalLinkClicks())
      this.dataTotalUniqueCalled.number = new Intl.NumberFormat().format(this.getTotalUniqueCalled())
      this.dataTotalMissedCalled.number = new Intl.NumberFormat().format(this.getTotalMissedCalled())
      this.dataTotalUniqueFormsSubmitted.number = new Intl.NumberFormat().format(this.getTotalUniqueFormsSubmitted())
      this.dataTotalUniqueMessages.number = new Intl.NumberFormat().format(this.getTotalUniqueMessages())
      this.dataTotalUniqueLeads.number = new Intl.NumberFormat().format(this.getTotalUniqueLeads())
      this.dataTotalLeadsOnProcess.number = new Intl.NumberFormat().format(this.getTotalLeadsOnProcess())
      this.dataTotalLeadsConverted.number = new Intl.NumberFormat().format(this.getTotalLeadsConverted())

      this.dataLeadsFunnel.leadPerformance = this.data.leadsFunnel

      this.dataRecentLeads.all = this.data.recentLeads.all
      this.dataRecentLeads.form = this.data.recentLeads.form
      this.dataRecentLeads.call = this.data.recentLeads.call
      this.dataRecentLeads.chatbot = this.data.recentLeads.chatbot

      this.dataHowPeopleDiscoveredYou.analyticPerformance = this.data.analyticPerformanceWithChannel
      this.dataHowPeopleDiscoveredYou.leadPerformance = this.data.leadPerformanceWithChannel

      this.dataDailyUniqueLeads.leadPerformance = this.data.leadPerformanceWithAccumulate

      this.dataAccumulatedDailyUniqueLeads.leadPerformance = this.data.leadPerformanceWithAccumulate
    },
    getTotalLinkClicks() {
      // eslint-disable-next-line no-nested-ternary
      return this.data.analyticPerformanceWithChannel.reduce((sum, item) => (item.linkClicks ? sum + item.linkClicks : item.clicks ? sum + item.clicks : sum), 0)
    },
    getTotalUniqueCalled() {
      return this.data.leadPerformanceWithChannel.totalCall.answered + this.data.leadPerformanceWithChannel.totalCall.missed
    },
    getTotalMissedCalled() {
      return this.data.leadPerformanceWithChannel.totalCall.missed
    },
    getTotalUniqueFormsSubmitted() {
      return this.data.leadPerformanceWithChannel.totalForm + this.data.leadPerformanceWithChannel.totalDirect
    },
    getTotalUniqueMessages() {
      return this.data.analyticPerformanceWithChannel.reduce((sum, item) => (item.messagingConversationsStarted ? sum + item.messagingConversationsStarted : sum), this.data.leadPerformanceWithChannel.totalDirect)
    },
    getTotalUniqueLeads() {
      return this.getTotalUniqueCalled() + this.getTotalUniqueFormsSubmitted() + this.data.leadPerformanceWithChannel.totalChatbot
    },
    getTotalLeadsOnProcess() {
      return this.data.totalLeadsOnProcess
    },
    getTotalLeadsConverted() {
      return this.data.totalLeadsConverted
    },
  },
}
</script>

<style scoped>
.number-block {
  height: 260px;
}
</style>
