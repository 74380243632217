<template>
  <b-tab lazy title="Team Members">
    <b-row>
      <b-col>
        <b-table
          show-empty
          striped
          sticky-header="100%"
          :responsive="true"
          :items="items"
          :fields="tableConfig.fields"
          :per-page="0"
          :sort-by="tableConfig.sortBy"
          :sort-direction="tableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
        />
      </b-col>
    </b-row>

    <div class="text-white">
      {{ hiddenMessage }}
    </div>

  </b-tab>
</template>

<script>
import moment from 'moment'
import {
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hiddenMessage: 'Loading', // ข้อความที่ซ่อนเอาไว้ เพื่อให้เกิดการเรียก updated() หลังจากข้อมูลมีการเปลี่ยนแปลงใหม่

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'First Name',
            key: 'first_name',
            stickyColumn: false,
          },
          {
            label: 'Last Name',
            key: 'last_name',
            stickyColumn: false,
          },
          {
            label: 'Email',
            key: 'email',
            sortable: false,
          },
        ],
      },
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newData) {
        this.items = newData.teamMembers

        this.hiddenMessage = ''
      },
    },
  },
  methods: {
  },
}
</script>
